<template>
    <div class="tab-checklist">
        <div class="tab-checklist__controls page__row">
            <div class="page__summary page__col">
                Total items: 72 | Major Lv.2: 2
            </div>

            <div class="page__group">
                <app-input class="page__input page__input--search"
                    :search="true"
                    v-model="search_word"
                    placeholder="Search checklist item..."
                    @search="search"
                />

                <app-input
                    class="page__input page__input--extra-small"
                    :value="`score: ${score}`"
                    readonly
                />

                <div class="page__button page__button--icon icon icon--file" />
            </div>
        </div>

        <app-dataset
            class="tab-checklist__dataset page__dataset"

            :fields="dataset.fields"
            :blocks="dataset.blocks"

            @change="onFieldChange"
            @action="onFieldAction"
        />

        <div class="tab-checklist__controls page__row">
            <div class="page__summary page__col">
                Civil | FACILITY ACCEPTANCE
            </div>
        </div>

        <app-table 
            class="tab-checklist__table page__table"

            :columns="table.columns"
            :rows="table.rows"

            @toggleCheckbox="onToggleCellCheckbox"
            @clickIcon="onClickCellIcon"
            @selectOption="onSelectCellOption"
        />

    </div>
</template>

<script>
import appInput from '@/components/app-input'
import appDataset from '@/components/app-dataset'
import appTable from '@/components/app-table'

const DATASET_FIELDS = {
    siteID:           { title: 'Site ID',           value: 'RBPLRT_1',                         action: 'eye' },
    region:           { title: 'Region',            value: 'NZ'                            },
    province:         { title: 'Province',          value: 'Bay of Pienty Region'                            },
    createdBy:        { title: 'Created by',        value: 'Cobus Brand, 29-09-2020, 10:14:52'               },
    lastUpdated:      { title: 'Last updated',      value: 'Riaan Hunt, 29-09-2020, 10:14:52'                },
    submittedBy:      { title: 'Submitted by',      value: 'Riaan Hunt, 29-09-2020, 10:14:52', merged: true  },

    responsible:      { title: 'Responsible',       value: 1,
        options: [
            { id: 1, name: 'Richard Sonne'  },
            { id: 2, name: 'Riaan Hunt'     },
            { id: 3, name: 'Adam Smith'     },
        ]
    },
    responsibleGroup: { title: 'Responsible group', value: 2,
        options: [
            { id: 1, name: 'Land_Downer'    },
            { id: 2, name: 'Civil_Downer'   },
        ]
    },
    companyRep:       { title: 'Company Rep.',      value: null,
        options: [
            { id: 1, name: 'Option 1'       },
            { id: 2, name: 'Option 2'       },
            { id: 3, name: 'Option 3'       },
        ],
    },
    towerCoRep:       { title: 'TowerCo Rep.',      value: null,
        options: [
            { id: 1, name: 'Option 1'       },
            { id: 2, name: 'Option 2'       },
            { id: 3, name: 'Option 3'       },
        ],
    },
    msRep:            { title: 'MS Rep.',           value: null,
        options: [
            { id: 1, name: 'Option 1'       },
            { id: 2, name: 'Option 2'       },
            { id: 3, name: 'Option 3'       },
        ],
    },
    vendorRep:        { title: 'Vendor Rep.',       value: null,
        options: [
            { id: 1, name: 'Option 1'       },
            { id: 2, name: 'Option 2'       },
            { id: 3, name: 'Option 3'       },
        ],
    },
}

const DATASET_BLOCKS = [
    ['siteID', 'region', 'province', 'responsible', 'responsibleGroup'],
    ['companyRep', 'towerCoRep', 'msRep', 'vendorRep'],
    ['createdBy', 'lastUpdated', 'submittedBy'],
]

const TABLE_COLUMNS = [
    { key: 'no',            title: 'No'                              },
    { key: 'items',         title: 'Inspection items', type: 'value' },
    { key: 'result',        title: 'Result'                          },
    { key: 'severity',      title: 'Severity',         type: 'value' },
    { key: 'photo',         title: 'Photo'                           },
    { key: 'remarks',       title: 'Remarks'                         },
    { key: 'responsible',   title: 'Responsible'                     },
    { key: 'clearedBy',     title: 'Cleared by'                      },
]

const resultOptions = [
    { key: 1, title: 'Not Started' },
    { key: 2, title: 'In Process'  },
    { key: 3, title: 'Completed'   }
]

const responsibleOptions = [
    { key: 1, title: 'Responsible'     },
    { key: 2, title: 'Not responsible' },
]

const TABLE_ROWS = [
    // by data preparation set 'uuid' for group-cols
    { group: true, 'uuid': 'group1', 'no': 'Info', 'items': 'Site details', },
    {
        'uuid':        'ef8a4b4e-24fb-48ab-82cd-ed490c4d9131',
        'no':          { value: '1', icon: {type: 'image', action: true}, checked: false },
        'items':       'RCG Site code',
        'result':      { value: 3, options: resultOptions },
        'severity':    'Minor Lv. 4',
        'photo':       {             icon: {type: 'download', action: true}},
        'remarks':     'RBPLRT',
        'responsible': { value: 1, options: responsibleOptions },
        'createdBy':   'Lorem ipsum',
    },
    {
        'uuid':        'ef8a4b4e-24fb-48ab-82cd-ed490c4d9132',
        'no':          { value: '2', icon: {type: 'image', action: true}, checked: false },
        'items':       'RCG Site name',
        'result':      { value: 3, options: resultOptions },
        'severity':    'Minor Lv. 4',
        'photo':       {             icon: {type: 'download', action: true}},
        'remarks':     'RBPLRT',
        'responsible': { value: 1, options: responsibleOptions },
        'createdBy':   'Lorem ipsum',
    },
    {
        'uuid':        'ef8a4b4e-24fb-48ab-82cd-ed490c4d9133',
        'no':          { value: '3', icon: {type: 'image', action: true}, checked: false },
        'items':       'Civil Const Company Name',
        'result':      { value: 3, options: resultOptions },
        'severity':    'Minor Lv. 4',
        'photo':       {             icon: {type: 'download', action: true}},
        'remarks':     'RBPLRT',
        'responsible': { value: 1, options: responsibleOptions },
        'createdBy':   'Lorem ipsum',
    },
    { group: true, 'uuid': 'group2', 'no': 'C100', 'items': 'Tower Foundation incl anchors', },
    {
        'uuid':        'ef8a4b4e-24fb-48ab-82cd-ed490c4d9134',
        'no':          { value: 'c101', icon: {type: 'image', action: true}, checked: false },
        'items':       'RCG Site code',
        'result':      { value: 3, options: resultOptions },
        'severity':    'Minor Lv. 3',
        'photo':       {             icon: {type: 'download', action: true}},
        'remarks':     'RBPLRT',
        'responsible': { value: 1, options: responsibleOptions },
        'createdBy':   'Lorem ipsum',
    },
        {
        'uuid':        'ef8a4b4e-24fb-48ab-82cd-ed490c4d9135',
        'no':          { value: 'c101', icon: {type: 'image', action: true}, checked: false },
        'items':       'RCG Site name',
        'result':      { value: 3, options: resultOptions },
        'severity':    'Minor Lv. 3',
        'photo':       {             icon: {type: 'download', action: true}},
        'remarks':     'RBPLRT',
        'responsible': { value: 1, options: responsibleOptions },
        'createdBy':   'Lorem ipsum',
    },
]

export default {
    components: {
        appInput,
        appDataset,
        appTable,
    },

    data() {
        return {
            score: 8,

            search_word: '',

            dataset: {
                fields: DATASET_FIELDS,
                blocks: DATASET_BLOCKS,
            },

            table: {
                columns: TABLE_COLUMNS,
                rows: TABLE_ROWS,
            },
        }
    },

    methods: {
        search(value) {
            // DO SEARCH
        },

        onFieldChange({ field, value }) {
            console.log(`Changed for ${this.fields[field].title} from ${this.fields[field].value} to ${value}`)

            this.fields[field].value = value
        },

        onFieldAction({ field, block }) {
            console.log(`Action for ${this.fields[field].title}`)
        },

        onToggleCellCheckbox( { col_key, row_key, row_key_field } ) {
            const row = this.table.rows.find( row => row[row_key_field] == row_key)

            const cell = row && row[col_key]

            if (cell && 'checked' in cell) {
                cell.checked = !cell.checked
            }
        },

        onClickCellIcon( { col_key, row_key, row_key_field } ) {
            console.log(`Clicked "${col_key}" icon for row ${row_key}`)
        },

        onSelectCellOption( { col_key, row_key, value, row_key_field } ) {
            const row = this.table.rows.find( row => row[row_key_field] == row_key)

            const cell = row && row[col_key]

            if (cell) {
                cell.value = value
            }
        },
    },
}
</script>

<style lang="scss">
.tab-checklist {
    &__controls {
        justify-content: space-between;

        @include media-bp(mob) {
            flex-wrap: wrap-reverse;
        }

        .page {
            &__group {
                flex: 1 1 auto;
                justify-content: flex-end;
            }

            &__input {
                &--search {
                    @include media-bp(mob) {
                        flex: 1 1 100%;
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

</style>