<template>
    <div class="app-table">
        <div class="app-table__head">
            <div
                v-for="(col, key) in columns" :key="`head-${key}`"
                class="app-table__col app-table__col--head"
            >
                {{col.title}}
            </div>
        </div>

        <div class="app-table__data">
            <div
                v-for="row in rows" :key="`row-${row[rowKeyField]}`"
                class="app-table__row"
                :class="{'app-table__row--group': row.group }"
            >
                <div
                    v-for="(col, key) in columns" :key="`row-${row[rowKeyField]}-${key}`"
                    class="app-table__col"
                    :class="{'app-table__col--value': col.type == 'value' }"
                >
                    <template v-if="isObject(row[col.key])">

                        <span v-if="row[col.key].icon"
                            class="app-table__col-icon icon"
                            :class="[
                                `icon--${row[col.key].icon.type}`,
                                {'app-table__col-icon': row[col.key].icon.action}
                            ]"
                            @click="row[col.key].icon.action && onClickIcon(col.key, row[rowKeyField])"
                        />

                        <app-dropdown-select v-if="isCellWithOptions(row[col.key]) && !readonly && !disabled"
                            class="app-table__col-select"
                            :value="row[col.key].value"
                            :options="row[col.key].options"

                            key-value="key"
                            key-title="title"

                            @change="(value) => onSelectOption(col.key, row[rowKeyField], value)"
                        />

                        <template v-else>
                            <app-checkbox v-if="isCellCheckbox(row[col.key])"
                                :value="row[col.key].checked"
                                :disabled="disabled"
                                :readonly="readonly"

                                @change="onToggleCheckbox(col.key, row[rowKeyField])"
                            >
                                {{ row[col.key].value || '' }}
                            </app-checkbox>

                            <span v-else class="app-table__value">
                                {{ row[col.key].value || '' }}
                            </span>
                        </template>

                    </template>

                    <span v-else class="app-table__value">
                        {{ row[col.key] || '' }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import appCheckbox from '@/components/app-checkbox'
import appDropdownSelect from '@/components/app-dropdown-select'


export default {
    props: {
        columns:     { type: Array,   required: true  },
        rows:        { type: Array,   required: true  },

        rowKeyField: { type: String,  default: 'uuid' },

        readonly:    { type: Boolean, default: false  },
        disabled:    { type: Boolean, default: false  },
    },
    components: {
        appCheckbox,
        appDropdownSelect,
    },
    data() {
        return {
        }
    },
    methods: {
        isObject(obj) {
            return obj instanceof Object
        },
        isCellWithOptions(cell) {
            return cell.options && Object.values(cell.options).length > 1
        },
        isCellCheckbox(cell) {
            return 'checked' in cell
        },

        onToggleCheckbox(col_key, row_key) {
            this.$emit('toggleCheckbox', { col_key, row_key, row_key_field: this.rowKeyField } )
        },

        onClickIcon(col_key, row_key) {
            this.$emit('clickIcon', { col_key, row_key, row_key_field: this.rowKeyField } )
        },

        onSelectOption(col_key, row_key, value) {
            this.$emit('selectOption', { col_key, row_key, value, row_key_field: this.rowKeyField })
        }
    },
}
</script>

<style lang="scss">
.app-table {
    &__head {
        display: flex;
    }

    &__row {
        display: flex;

        border-bottom: 2px solid rgba($brand-color-secondary, 0.5);
    }

    &__col {
        flex: 1 1 auto;
        display: flex;
    }
}
</style>