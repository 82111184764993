<template>
    <div class="app-dataset">
        <div
            v-for="(block_fields, block) in calculated_blocks"
            :key="`info-block-${block}`"
            class="app-dataset__block">

            <app-infofield v-for="(field, key) in block_fields" :key="`infofield-${block}-${key}`"
                class="app-dataset__field"

                :label="fields[field].title"
                :value="fields[field].value"
                :options="fields[field].options"

                :action="fields[field].action"
                :merged="fields[field].merged"

                @action="() => $emit('action', { field: field, block })"
                @change="(value) => $emit('change', { field: field, value })"
            />

        </div>
    </div>

</template>

<script>
import appInfofield from '@/components/app-infofield'

export default {
    props: {
        fields: { type: Object, default: () => ({}) },
        blocks: { type: Array,  default: () => ([]) },
    },
    components: {
        appInfofield,
    },

    computed: {
        calculated_blocks() {
            const isArrayOfArrays = (arr) => Array.isArray(arr) && arr.every(item => Array.isArray(item))
            const filterExistedFields = (arr) => arr.filter((item) => this.fields[item] && 'title' in this.fields[item] && 'value' in this.fields[item])

            const array_blocks = isArrayOfArrays(this.blocks) ? this.blocks : [ this.blocks ]

            const calculated_blocks = array_blocks.map(filterExistedFields)

            return calculated_blocks

        }
    },

}
</script>

<style lang="scss">
.app-dataset {
    column-count: 3;
    column-gap: $page-gutter;

    @include media-bp(ltp) {
        column-count: 2;
    }

    @include media-bp(tab) {
        column-count: 1;
    }

    &__block {
        break-inside: avoid;
    }

    &__field {
        margin-bottom: 5px;
    }
}
</style>