/**
 * The file enables to import all tabs in a one-shot manner.
 * There should not be any reason to edit this file.
 */

const files = require.context('.', false, /\.vue$/)
const tabs = {}

files.keys().forEach(tab => {

    const tab_name = 'tab-' + tab.replace(/(\.\/|\.vue)/g, '')

    tabs[tab_name] = files(tab).default
})

export default tabs
