<template>
    <div class="audit">
        <audit-controls />

        <app-tabs class="audit__tabs page__tabs page__tabs--secondary" v-model="tab" :tabs="tabs" type="secondary"/>
        <component :is="currentTabComponent"
            class="audit__tab" :class="`audit__tab--${tab.id}`" />
    </div>
</template>

<script>
import appTabs from '@/components/app-tabs'
import auditControls from './controls.vue'

import tabs from './tabs'

export default {
    components: {
        auditControls,
        appTabs,
        ...tabs,
    },

    data() {
        return {
            tabs: [
                { id: 'checklist',      title: 'Checklist' },
                { id: 'site-visit-log',   title: 'Site visit log' },
                { id: 'docs-videos',     title: 'Docs/Videos' },
                { id: 'punchist',       title: 'Punchlist' },
                { id: 'history',        title: 'History' },
                { id: 'asbuilt',        title: 'Finalize As-built' },
            ],

            tab_id: 'Checklist',
        }
    },

    computed: {
        tab: {
            get() {
                return this.tabs.find( tab => tab.id == this.tab_id ) || this.tabs[0]
            },

            set(tab) {
                this.tab_id = tab.id
            }
        },

        currentTabComponent() {
            return `tab-${this.tab.id}`
        }
    }
}
</script>

<style lang="scss">
</style>