var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"app-checkbox",class:{
        'app-checkbox--checked': _vm.value,
        'app-checkbox--partially': _vm.partiallyChecked,
        'app-checkbox--disabled': _vm.disabled,
        'app-checkbox--readonly': _vm.readonly
    },on:{"click":function($event){$event.stopPropagation();}}},[_c('span',{staticClass:"app-checkbox__icon",style:({
            width: _vm.iconSize,
            height: _vm.iconSize,
            marginRight: _vm.iconIndent,
        })}),_c('span',{staticClass:"app-checkbox__label"},[_vm._t("default")],2),_c('input',{staticClass:"app-checkbox__input",attrs:{"type":"checkbox","disabled":_vm.disabled,"readonly":_vm.readonly},domProps:{"checked":_vm.value},on:{"input":_vm.onChange,"change":_vm.onChange}})])}
var staticRenderFns = []

export { render, staticRenderFns }