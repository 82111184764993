<template>
    <div class="app-tabs"
        :class="[`app-tabs--${type}`, colorOption ? `app-tabs--${colorOption}` : '' ]"
    >
        <button
            class="app-tabs__button"
            :class="{ 'app-tabs__button--active': value && tab[valueId] == value[valueId] }"

            v-for="tab in tabs" :key="tab.id"

            @click="changeTab(tab)"
        >{{ tab.title }}</button>
    </div>
</template>

<script>
import appDropdownSelect from '@/components/app-dropdown-select'

export default {
    props: {
        value:         { default: null },
        tabs:          { type: [Array, Object], required: true },
        valueId:       { default: 'id' },
        type:          { default: 'primary' },
        colorOption:   { default: ''},
    },

    components: {
        appDropdownSelect,
    },

    methods: {
        changeTab(tab) {
            if (!this.value || tab[this.valueId] != this.value[this.valueId]) {
                this.$emit("input", tab)
                this.$emit("change", tab)
            }
        }
    },

    computed: {
        value_tab: {
            get() {
                return this.value ? this.value[this.valueId] : null
            },
            set(val) {
                const tab = this.tabs.find(tab => tab[this.valueId] == val)

                if (tab) {
                    this.changeTab(tab)
                }
            },
        },
    },
}
</script>

<style lang="scss" scoped>
.app-tabs {
    $root: &;

    display: flex;

    width: 100%;

    &--primary {
        background-color: $white;

        #{$root}__button {
            display: flex;
            align-items: center;
            justify-content: center;

            min-width: 210px;
            height: $page-tabs-height;
            padding: 8px 35px 0;

            font-size: 32px;
            line-height: 1.25px;
            font-weight: 600;
            text-align: center;
            color: $text-color-secondary;

            &:hover,
            &--active {
                color: $brand-color-secondary-dark;
                background-color: rgba($brand-color-secondary, 0.1);
            }

            @include media-bp(tab) {
                height: $page-tabs-height-tab;
            }
        }
    }

    &--secondary {
        #{$root}__button {
            margin-right: 24px;

            font-size: 24px;
            line-height: 1.33;
            font-weight: 500;
            color: $text-color-secondary;

            border-bottom: 3px solid transparent;

            &:hover,
            &--active {
                color: $text-color-base;
                border-color: $brand-color-secondary;
            }

            @include media-bp(tab) {
                margin-right: 16px;
                font-size: 20px;
                line-height: 1.6;
            }
        }

        @each $color-option, $color in $color-options {
            &#{$root}--#{$color-option} {
                #{$root}__button {
                    &:hover,
                    &--active {
                        border-color: $color;
                    }
                }
            }
        }

    }
}

</style>