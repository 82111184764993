<template>
    <div class="audit__controls page__row page__controls">
        <div class="page__group audit__controls-group audit__controls-group--outer">

            <div class="page__group audit__controls-group audit__controls-group--first">
                <div class="page__group audit__controls-group audit__controls-group--inner">
                    <button class="page__button btn btn--round btn--left" />
                    <button class="page__button btn btn--round btn--reload" />
                </div>

                <app-input-select
                    class="page__input page__input--select page__input--small"
                    v-model="value1"
                    :options="options1"
                />
            </div>

            <div class="page__group audit__controls-group audit__controls-group--middle">
                <app-input-select
                    class="page__input page__input--select page__input--medium"
                    v-model="value2"
                    :options="options2"
                />

                <button class="audit__button page__button btn btn--round btn--reload" />
            </div>

        </div>

        <div class="page__group audit__controls-group audit__controls-group--last">
            <app-input-select
                class="page__input page__input--select"
                v-model="value3"
                :options="options3"
            />
        </div>
    </div>
</template>

<script>
import appInputSelect from '@/components/app-input-select'

export default {
    components: {
        appInputSelect,
    },
    data() {
        return {
            options1: [
                {id: 1, name: 'rbplrt_1'},
                {id: 2, name: 'rbplrt_2'},
                {id: 3, name: 'rbplrt_3'},
                {id: 4, name: 'rbplrt_4'},
                {id: 5, name: 'rbplrt_5'},
            ],
            value1: 1,

            options2: [
                {id: 0, name: 'current version'},
                {id: 1, name: 'previous version'},
            ],
            value2: 0,

            options3: [
                {id: 1, name: 'facility_acceptance_v1'},
                {id: 2, name: 'facility_acceptance_v2'},
                {id: 3, name: 'facility_acceptance_v3'},
                {id: 4, name: 'facility_acceptance_v4'},
                {id: 5, name: 'facility_acceptance_v7'},
            ],
            value3: 3,
        }
    },
}
</script>

<style lang="scss">
.audit__controls {
    @include media-bp(ltp) {
        flex-wrap: wrap;
    }

    justify-content: space-between;

    &-group {

        &--outer {
            flex: 0 1 100% / 3 * 2;
            justify-content: space-between;

            @include media-bp(ltp) {
                flex-basis: 100%;
                max-width: 100%;
            }

            @include media-bp(mob) {
                flex-wrap: wrap;
            }
        }

        &--first {
            flex: 1 1 $page-input-max-width + $page-gutter;
            justify-content: space-between;

            @include media-bp(ltp) {
                flex-basis: 50%;
                max-width: 50%;
            }

            @include media-bp(mob) {
                flex-basis: 100%;
                max-width: 100%;
            }
        }

        &--middle {
            flex: 0 1 $page-input-max-width + $page-gutter;

            @include media-bp(ltp) {
                flex-basis: 50%;
                max-width: 50%;
            }

            @include media-bp(mob) {
                flex-basis: 100%;
                max-width: 100%;
            }
        }

        &--last {
            flex: 0 1 $page-input-max-width + $page-gutter;

            @include media-bp(ltp) {
                flex-basis: 50%;
                max-width: 50%;
            }

            @include media-bp(mob) {
                flex-basis: 100%;
                max-width: 100%;
            }
        }

        &--inner {
            flex: 0 0 auto;
        }

        .page {
            &__input {
                @include media-bp(ltp) {
                    flex-basis: 100%;
                    max-width: 100%;
                }
            }
        }
    }
}
</style>