<template>
    <div class="app-input"
         :class="{
             'app-input--disabled': disabled,
             'app-input--readonly': readonly,
             'app-input--typing': typing,
             'app-input--error': error,
             'app-input--focused': focused,
             'app-input--password': is_password,
             'app-input--icon-right': is_password || is_search,
         }"
         @click="focus"
    >

        <input
            :type="computed_type"
            :value="value"
            :disabled="disabled"
            :maxlength="maxlength"
            :placeholder="placeholder"
            :readonly="readonly"

            class="app-input__input"

            ref="field"

            @focus="handleFocus"
            @blur="handleBlur"
            @keyup.enter="onEnter"
            @input="handleChange($event.target.value)"
        >

        <div class="app-input__icon app-input__icon--right icon"
            :class="{
                'icon--eye':        is_password && !show_password,
                'icon--eye-closed': is_password && show_password,
                'icon--search':     is_search,
            }"

            @click="clickIcon"
        />

        <label class="app-input__label" v-if="!error">
            <span class="app-input__label-text">{{ label }}<i v-if="required">*</i></span>
        </label>

        <div class="app-input__error-message" v-if="error">{{ error }}</div>
    </div>
</template>

<script>

export default {
    props: {
        value:          { required: false                },
        type:           { type: String,  default: 'text' },
        required:       { type: Boolean, default: false  },
        disabled:       { type: Boolean, default: false  },
        error:          {                default: false  },
        icon:           { type: String,  default: ''     },
        label:          { type: String,  default: ''     },
        search:         { type: Boolean, default: false  },
        maxlength:      { default: 100                   },
        placeholder:    { default: ''                    },
        debounce:       { type: Number,  default: 0      },
        readonly:       { type: Boolean, default: false  },

        password_helper: { type: Boolean, default: false },
    },

    data() {
        return {
            focused: false,
            debounce_timeout: null,
            show_password: false,
        }
    },

    computed: {
        typing() {
            return this.focused || (this.value || this.value === 0)
        },

        is_password() {
            return this.type === 'password'
        },

        is_search() {
            return this.search
        },

        computed_type() {
            if (this.type === 'password') {
                return this.show_password ? 'text' : 'password'
            } else {
                return this.type
            }
        },
    },

    methods: {
        clickIcon() {
            if (this.is_password) this.togglePassword()

            if (this.is_search) this.onSearch()
        },

        togglePassword() {
            this.show_password = !this.show_password
        },

        focus() {
            this.$refs.field.focus()

            return this
        },

        handleFocus() {
            this.focused = true
            this.$emit('focus')
        },

        handleBlur() {
            this.focused = false
            this.$emit('blur')
        },

        handleChange(value) {
            this.$emit('input', value)
            this.emitDebounce('change', value)
        },

        onEnter($event) {
            this.onSearch($event.target.value)
            this.$emit('input', $event.target.value)
            this.$emit('change', $event.target.value)
            this.$emit('onenter', $event.target.value)
            this.clearDebounce()
        },

        onSearch(value = this.value) {
            this.$emit('search', value)
        },

        emitDebounce(event, value) {
            if (this.debounce) {
                this.clearDebounce()

                this.debounce_timeout = setTimeout(() => {
                    this.$emit(event, value)
                    this.debounce_timeout = null
                }, this.debounce)
            } else {
                this.$emit(event, value)
            }
        },
        clearDebounce() {
            if (this.debounce_timeout !== null) {
                clearTimeout(this.debounce_timeout)

                this.debounce_timeout = null
            }
        },
    },
}
</script>

<style lang="scss">
.app-input {
    $root: &;

    position: relative;
    height: $input-height;

    font-family: $font-family-primary;

    &__input {
        display: block;
        width: 100%;
        height: 100%;

        padding: $input-padding;

        font-size: $input-font-size;
        line-height: $input-line-height;
        color: $text-color-base;
        background-color: $white;

        text-transform: uppercase;

        border: 1px solid $brand-color-secondary;
        border-radius: 0;

        #{$root}--password & {
            text-transform: none;
        }

        #{$root}--icon-right & {
            padding-right: $input-padding-right + $input-icon-width + $input-padding-right;
        }

        &[type=password] {
            letter-spacing: normal;

            &::placeholder {
                letter-spacing: inherit;
            }
        }
    }

    &__label {
        position: absolute;
        top: calc(50% + 4px);
        left: $input-padding-left;

        max-width: calc(100% - #{$input-padding-right} - #{$input-icon-width} - 1px);

        font-weight: normal;

        transform: translateY(-50%);

        transition: $transition-duration-primary;

        font-size: $input-font-size;
        line-height: $input-line-height;
        color: $text-color-base;

        &-text {
            position: relative;

            display: block;

            @include text-overflow();
        }

        i {
            color: $error-color;
            font-style: normal;
        }

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transition: $transition-duration-primary;
        }
    }

    &__error-message {
        position: absolute;
        bottom: calc(100% + 4px);
        left: 0;
        width: 100%;
        max-width: 100%;
        padding-left: $input-padding-left;
        font-size: 16px;
        line-height: 1.2;
        color: $error-color;
        text-align: left;

        @include text-overflow();
    }

    &__icon {
        position: absolute;
        top: 1px;
        bottom: 1px;
        width: $input-icon-width;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: 100%;
            height: auto;
            g, path {
                //stroke:;
                //fill:;
            }
        }

        &--right {
            right: $input-padding-right;
            left: auto;
            -webkit-tap-highlight-color: transparent;

            cursor: pointer;
        }
    }

    &--typing & {
        &__label {
            display: none;
        }

        &__input {
            color: $text-color-base;
            background-color: $white;

            &::placeholder {
                color: transparent;
            }
        }


        &__icon {
            svg {
                g, path {
                    // stroke:;
                    // fill:;
                }
            }
        }
    }

    &--disabled {
        opacity: 0.4;
    }

    &--error & {
        &__input {
            background-color: rgba($error-color, 0.5);
            border-color: transparent;
        }

        &__label {
            color: $error-color;
        }

        &__icon {
            svg {
                g, path {
                    // stroke: $error-color;
                    // fill: $error-color,
                }
            }
        }
    }

    @include media-bp(tab) {
        height: $input-height-tab;

        &__input {
            padding: $input-padding-tab;

            font-size: $input-font-size-tab;
            line-height: $input-line-height-tab;

            #{$root}--password & {
                padding-right: $input-padding-right-tab + $input-icon-width-tab + $input-padding-right-tab;
            }
        }

        &__label {
            left: $input-padding-left-tab;

            max-width: calc(100% - #{$input-padding-right-tab} - #{$input-icon-width-tab} - 1px);

            font-size: $input-font-size-tab;
            line-height: $input-line-height-tab;
        }

        &__error-message {
            padding-left: $input-padding-left-tab;
        }

        &__icon {
            width: $input-icon-width-tab;

            &--eye {
                right: $input-padding-right-tab;
            }
        }
    }
}
</style>
