<template>
    <app-dropdown
        class="dropdown-select" :class="{'dropdown-select--selected': selected}"

        ref="dropdown"

        :disabled="disabled"

        v-bind="$attrs"

        @open="$emit('open')"
        @close="$emit('close')"
    >
        <template #header>
            <div class="dropdown-select__selected">
                {{ selected ? title : defaultTitle }}
            </div>
        </template>

        <template #body>
            <div class="dropdown-select__options">
                <div 
                    v-for="option in options" :key="option[keyValue]"
                    class="dropdown-select__option" :class="{ 'dropdown-select__option--active': option[keyValue] == value }"

                    @click="select(option)"
                >
                    {{ option[keyTitle] }}
                </div>
            </div>
        </template>
    </app-dropdown>
</template>

<script>
import AppDropdown from '@/components/app-dropdown'

export default {
    props: {
        value:        { required: true },
        options:      { type: [Array, Object], required: true       },

        defaultTitle: { type: String,  default: '--please select--' },

        keyValue:     { type: String,  default: 'id'                },
        keyTitle:     { type: String,  default: 'name'              },

        disabled:     { type: Boolean, default: false               },
    },

    components: {
        AppDropdown,
    },

    computed: {
        title() {
            const option = Object.values(this.options)
                .find(item => item[this.keyValue] === this.value)

            return option ? option[this.keyTitle] : ''
        },

        selected() {
            return this.title && this.title.length > 0
        },
    },

    methods: {
        select(option) {
            // this.self = true

            this.$emit('input', option[this.keyValue])
            this.$emit('select', option[this.keyValue])

            if (option[this.keyValue] !== this.value) {
                this.$emit('change', option[this.keyValue])
            }

            this.close()
        },

        open() {
            this.$refs.dropdown.open()
        },

        close() {
            this.$refs.dropdown.close()
        },

        toggle() {
            this.$refs.dropdown.toggle()
        },

    },
}
</script>

<style lang="scss">
.dropdown-select {
    &__selected {
        padding: 9px 16px 5px;

        @include text-overflow;
    }
    &__options {
        background-color: $white;
    }
    &__option {
        padding: 9px 16px 5px;
        cursor: pointer;

        &--active {
            color: $brand-color-secondary;
            cursor: default;
        }

        &:hover {
            background-color: rgba($black, 0.1);
        }
    }
}

</style>