<template>
    <app-dropdown-select 
        class="app-input-select"
        :value="value"
        :options="options"

        @input="(id) => $emit('input', id)"
        @select="(id) => $emit('select', id)"
        @change="(id) => $emit('change', id)"
        @open="$emit('open')"
        @close="$emit('close')"

        v-bind="$attrs"

        body-max-height="250px"
        body-top="calc( 100% + 6px )"
    />

</template>

<script>
import appDropdownSelect from '@/components/app-dropdown-select'

export default {
    props: ['value', 'options'],
    components: {
        appDropdownSelect,
    }
}
</script>

<style lang="scss">
.app-input-select {
    height: $input-height;

    text-transform: uppercase;

    font-size: $input-font-size;
    line-height: $input-line-height;
    color: $text-color-base;

    .dropdown-select {
        &__selected {
            position: absolute;
            left: 0;
            right: 0;

            padding: $input-padding;
            padding-right: $input-padding-right + $input-icon-width + $input-padding-right;

            background-color: $white;

            border: 1px solid $brand-color-secondary;
            border-radius: 0;
        }

        &__options {
            padding-top: 8px;
        }

        &__option {
            padding-top: 10px;
            padding-bottom: 4px;
            padding-left: 18px;
        }
    }

    @include media-bp(tab) {
        height: $input-height-tab;

        font-size: $input-font-size-tab;
        line-height: $input-line-height-tab;

        .dropdown-select {
            &__selected {
                padding: $input-padding-tab;
                padding-right: $input-padding-right-tab + $input-icon-width + $input-padding-right-tab;
            }
        }
    }
}
</style>