var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-input",class:{
         'app-input--disabled': _vm.disabled,
         'app-input--readonly': _vm.readonly,
         'app-input--typing': _vm.typing,
         'app-input--error': _vm.error,
         'app-input--focused': _vm.focused,
         'app-input--password': _vm.is_password,
         'app-input--icon-right': _vm.is_password || _vm.is_search,
     },on:{"click":_vm.focus}},[_c('input',{ref:"field",staticClass:"app-input__input",attrs:{"type":_vm.computed_type,"disabled":_vm.disabled,"maxlength":_vm.maxlength,"placeholder":_vm.placeholder,"readonly":_vm.readonly},domProps:{"value":_vm.value},on:{"focus":_vm.handleFocus,"blur":_vm.handleBlur,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onEnter($event)},"input":function($event){return _vm.handleChange($event.target.value)}}}),_c('div',{staticClass:"app-input__icon app-input__icon--right icon",class:{
            'icon--eye':        _vm.is_password && !_vm.show_password,
            'icon--eye-closed': _vm.is_password && _vm.show_password,
            'icon--search':     _vm.is_search,
        },on:{"click":_vm.clickIcon}}),(!_vm.error)?_c('label',{staticClass:"app-input__label"},[_c('span',{staticClass:"app-input__label-text"},[_vm._v(_vm._s(_vm.label)),(_vm.required)?_c('i',[_vm._v("*")]):_vm._e()])]):_vm._e(),(_vm.error)?_c('div',{staticClass:"app-input__error-message"},[_vm._v(_vm._s(_vm.error))]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }