<template>
    <div class="infofield"
         :class="{
             'infofield--disabled': disabled,
             'infofield--readonly': readonly,
             'infofield--merged':   merged,
             'infofield--action':   action,
         }"
    >

        <label class="infofield__label">
            <span class="infofield__label-text">
                {{ label }}
            </span>
            <span v-if="merged" class="infofield__label-text infofield__label-text--value">
                {{displayed_value }}
            </span>
        </label>

        <div v-if="!merged" class="infofield__data">
            <app-dropdown-select v-if="with_options && !readonly && !disabled"
                class="infofield__select"
                :value="value"
                :options="options"

                :default-title="`Select ${label}`"

                :key-value="keyValue"
                :key-title="keyTitle"

                @input="(value) => $emit('input', value)"
                @select="(value) => $emit('select', value)"
                @change="(value) => $emit('change', value)"
                @open="$emit('open')"
                @close="$emit('close')"

                v-bind="$attrs"

                body-max-height="250px"
                body-top="calc( 100% + 6px )"
            />

            <div v-else class="infofield__value">
                {{displayed_value}}
                <span 
                    v-if="action"
                    class="infofield__action-icon icon"
                    :class="`icon--${action}`"
                    @click="$emit('action')"
                />
            </div>
        </div>

    </div>
</template>

<script>
import appDropdownSelect from '@/components/app-dropdown-select'

export default {
    props: {
        label:          { type: String,          default: ''       },

        value:          { required: true                           },
        options:        { type: [Array, Object], default: () => [] },
        keyValue:       { type: String,          default: 'id'     },
        keyTitle:       { type: String,          default: 'name'   },

        action:         { type: String,          default: ''       },

        merged:         { type: Boolean,         default: false    },

        readonly:       { type: Boolean,         default: false    },
        disabled:       { type: Boolean,         default: false    },
    },

    components: {
        appDropdownSelect,
    },

    data() {
        return {
        }
    },

    computed: {
        with_options() {
            return Object.values(this.options).length > 1
        },

        displayed_value() {
            const option = Object.values(this.options).find( (item) => item[this.keyValue] === this.value)

            if (this.value) {
                return ( option && option[this.keyTitle] ) || this.value
            } else {
                return ''
            }
        },
    },
}
</script>

<style lang="scss">
.infofield {
    $root: &;

    display: flex;
    align-items: center;

    position: relative;

    padding-left: 16px;
    border-bottom: 1px solid $brand-color-secondary;

    min-height: 44px;

    &__label {
        flex: 0 0 37%;
        max-width: 180px;
        display: flex;

        @include media-bp(tab) {
            flex-basis: 50%;
            max-width: 50%;
        }

        &-text {
            padding-top: 9px;
            padding-bottom: 5px;

            &--value {
                margin-left: 5px;
            }
        }

        #{$root}--merged & {
            flex-basis: 100%;
            max-width: 100%;

            flex-wrap: wrap;

            &-text {
                overflow: visible;
            }
        }
    }

    &--merged {
        font-weight: 500;
    }

    &__data {
        flex: 1 1 auto;
    }

    &__value {
        position: relative;

        padding: 9px 16px 5px;

        #{$root}--action & {
            padding-right: 40px + 16px;
        }

        color: $text-color-secondary;
    }

    &__action-icon {
        position: absolute;
        top: 1px;
        bottom: 1px;
        right: 16px;
        left: auto;
        // width: $input-icon-width;
        display: flex;
        justify-content: center;
        align-items: center;

        -webkit-tap-highlight-color: transparent;

        cursor: pointer;

        color: $text-color-base;

        &:hover {
            color: $brand-color-secondary;
        }

        svg {
            width: 100%;
            height: auto;
        }
    }

    &--disabled {
        opacity: 0.4;
    }
}
</style>
