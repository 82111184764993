<template>
    <label class="app-checkbox"
        :class="{
            'app-checkbox--checked': value,
            'app-checkbox--partially': partiallyChecked,
            'app-checkbox--disabled': disabled,
            'app-checkbox--readonly': readonly
        }"

        @click.stop
    >
        <span
            class="app-checkbox__icon"
            :style="{
                width: iconSize,
                height: iconSize,
                marginRight: iconIndent,
            }"
        />

        <span class="app-checkbox__label"><slot /></span>

        <input
            class="app-checkbox__input"
            type="checkbox"

            :checked="value"
            :disabled="disabled"
            :readonly="readonly"

            @input="onChange"
            @change="onChange"
        >
    </label>
</template>

<script>
export default {
    props: {
        value:          { required:         true },

        disabled:       { type: Boolean, default: false },
        readonly:       { type: Boolean, default: false },

        iconSize:       { type: String,  default: '12px'},
        iconIndent:     { type: String,  default: '6px' },

        partiallyChecked: { type: Boolean, default: false },
    },

    methods: {
        onChange(event) {
            if (!this.readonly) {
                this.$emit('input', event.target.checked)

                if (event.target.checked != this.value) {
                    this.$emit('change', event.target.checked)
                }
            }
        },
    },
}
</script>

<style lang="scss">
.app-checkbox {
    $root: &;

    width: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    user-select: none;
    cursor: pointer;

    &__input {
        display: none;
    }

    &__icon {
        flex-shrink: 0;
        display: inline-block;

        color: $brand-color-secondary-dark;
        border: 2px solid;

        margin-bottom: 0.3em;

        display: flex;
        justify-content: center;

        &::before {
            content: '';
            display: block;
            width: 50%;
            height: 150%;
            color: transparent;
            transform: rotate(45deg) translate(-60%, -55%);

            border: 2px solid;
            border-top-color: transparent;
            border-left-color: transparent;
        }

        #{$root}--checked & {
            &::before {
                color: $brand-color-secondary-dark;
            }
        }

        #{$root}--partially & {}
    }

    &:not(&--readonly):not(&--disabled) {
        &:hover {
            color: $brand-color-secondary-dark;
        }
    }

    &--disabled {
        opacity: .4;
        cursor: default;

        #{$root} {
            &__icon {
                color: $text-color-secondary;
            }
        }
    }

    &--readonly {
        cursor: default;
    }
}
</style>